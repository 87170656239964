import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { EnvironmentService } from '@libs/core/services';

import { TPowerBISettingsDto } from '../types';

@Injectable({ providedIn: 'root' })
export class PowerBiApiService {
  private _urlBuilder: OcpUrlBuilder;

  constructor(
    private readonly _http: HttpClient,
    private readonly _environmentService: EnvironmentService,
  ) {
    this._urlBuilder = new OcpUrlBuilder(
      `${this._environmentService.getProp('API_PREFIX')}/projects`,
    );
  }

  public getConfigs$(projectId: number): Observable<TPowerBISettingsDto> {
    const url = this._urlBuilder.buildUrl([projectId, 'bi', 'powerbi']);
    return this._http.get<TPowerBISettingsDto>(url);
  }
}
