import { createActionGroup, emptyProps, props } from '@ngrx/store';

import type { TPowerBiConfig } from '../types';

export const powerBiActions = createActionGroup({
  source: 'PowerBi',
  events: {
    'Load Configs': emptyProps(),
    'Load Configs Success': props<{ result: TPowerBiConfig }>(),
    'Load Configs Failure': props<{ error: any }>(),
  },
});
