import { createFeature, createReducer, on } from '@ngrx/store';

import { powerBiActions } from './power-bi.actions';
import { type TPowerBiConfig } from '../types';

export type TPowerBiState = {
  config: TPowerBiConfig | null;
  selectedReportId: string | null;
  loadingInitial: boolean;
  error: any;
};

const initialState: TPowerBiState = {
  config: null,
  selectedReportId: null,
  loadingInitial: false,
  error: null,
};

const powerBiReducer = createReducer(
  initialState,
  on(powerBiActions.loadConfigs, (state) => ({
    ...state,
    selectedReportId: null,
    loadingInitial: true,
    error: null, // Clear error when loading starts
  })),
  on(powerBiActions.loadConfigsSuccess, (state, response) => ({
    ...state,
    loadingInitial: false,
    error: null,
    config: response.result,
  })),
  on(powerBiActions.loadConfigsFailure, (state, { error }) => ({
    ...state,
    loadingInitial: false,
    error,
  })),
);

export const powerBiFeature = createFeature({
  name: 'powerBi',
  reducer: powerBiReducer,
});
